import React from "react";
import Layout from "../components/Layout";
import { LayoutModeProvider } from "../containers/LayoutModeContext";
import Hero from "../components/Hero";
import ThreeCol from "../partials/ThreeCol";
import SideBySide from "../partials/SideBySide";
import LeftyRighty from "../partials/LeftyRighty";
import Illustrated from "../partials/Illustrated";
import Blockquote from "../partials/Blockquote";
import FeatureImage from "../partials/FeatureImage";

export default function IndexPage() {
	return (
		<LayoutModeProvider mode="standard">
			<Layout>
				<Hero>Standard Layout</Hero>
				<ThreeCol />
				<SideBySide />
				<LeftyRighty />
				<Illustrated />
				<Blockquote />
				<FeatureImage />
			</Layout>
		</LayoutModeProvider>
	);
}
